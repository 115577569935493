<template>
    <div id="ekkBreadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item 
            v-for="(item,index) in breadcrumbList" 
            :key="index" 
            v-show="index>0"
            >
                <span @click="back(index)"
                    :class="breadcrumbList.length>3 && index > 1 && index < breadcrumbList.length ? 'back' : ''"
                >{{item.meta.title}}</span>
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
export default {
    name:'ekk-breadcrumb',
    props:{
        breadcrumbList:{
            type:Array,
            require:true,
            default:() =>{
                return [];
            }
        }
    },
    methods:{
        back(index){
            if(this.breadcrumbList.length>3 && index > 1 && index < this.breadcrumbList.length){
                this.$router.back();
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>