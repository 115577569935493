<template>
    <div id="ekkHeader">
		<div class="ekk-header-title">梵木智能便利店</div>
		<div class="ekk-header-login">
			<!-- <div class="ekk-header-role">{{userInfo.name}}</div>
			<div class="ekk-header-block">|</div>
			<div @click="handleLoginOut" class="ekk-header-logout">退出登录</div> -->
		</div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { USER_SIGN_OUT } from "@/store/types";
export default {
	name:'ekk-header',
	data(){
		return {
		}
	},
	computed:{
		...mapState({
			userInfo: (state) => state.user.userInfo,
		}),
	},
	methods:{
		...mapActions([ USER_SIGN_OUT ]),
		// 退出登录
		handleLoginOut() {
			this.$confirm('确定要退出吗？','提示',{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: "warning",
			}).then(() => {
			this.$message({
				type: "success",
				duration: 2000,
				message: '退出成功！',
			});
			this[USER_SIGN_OUT]();
			}).catch(() => {});
		},
	}
}
</script>

<style lang="scss" scoped>

</style>