<template>
    <div id="ekkFooter">
        <!-- © 2021 EKK All Rights Reserved -->
    </div>
</template>

<script>
    export default {
        name:'ekk-footer',
        
    }
</script>

<style lang="scss" scoped>
#ekkFooter{
    width: 100%;
    min-width: 1280px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 12px;
    color: #bbb;
}
</style>