<template>
    <div id='layout'>
        <ekk-header />
        <ekk-menu />
        <div class="layout-pages">
            <ekk-breadcrumb :breadcrumbList="$route.matched"/>
            <div class="layout-content">
                <router-view />
            </div>
        </div>
        <ekk-footer />
    </div>
</template>

<script>
import EkkHeader from '@/components/base/ekk_header.vue'
import EkkMenu from '@/components/base/ekk_menu.vue'
import EkkBreadcrumb from '@/components/base/ekk_breadcrumb.vue'
import EkkFooter from '@/components/base/ekk_footer.vue'

export default {
    data(){
        return{
        }
    },
    components:{
        EkkHeader,
        EkkMenu,
        EkkBreadcrumb,
        EkkFooter,
    },
    created(){
    },
    mounted(){
        // this.$route.matched
        // let matched=this.$route.matched.filter(item=>item.name);
    },
    methods:{
    }

}
</script>

<style lang="scss" scoped>
</style>