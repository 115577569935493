<template>
    <div id="ekkMenu">
        <div class="ekk-menu-logo">
            <img src="../../assets/images/logo.png"/>
        </div>
        <div class="ekk-menu-wrap">
            <el-menu
            class="ekk-menu-list"
            @open="handleOpen"
            @close="handleClose"
            :router="true"
            :collapse="false"
            :default-active="$route.path"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b">
                <div v-for="(item, i) in menuList.children" :key="i" >
                    <el-submenu v-if="item.children != null" :index="item.path">
                        <template slot="title">
                            <i :class="item.meta.icon"></i>
                            <span>{{item.meta.title}}</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item v-for="(con, j) in item.children" :key="j" :index="con.path" >{{con.meta.title}}</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-menu-item  v-else :index="item.path" >
                        <i :class="item.meta.icon"></i>
                        <span slot="title">{{item.meta.title}}</span>
                    </el-menu-item>
                </div>
            </el-menu>
        </div>
        
    </div>
</template>

<script>
import routes from '@/router/routes.js';
import { mapState } from "vuex";
export default {
    name:'ekk-menu',
    data(){
        return {
            menuList:routes[0],
        }
    },
    computed:{
		// ...mapState({
		// 	userInfo: (state) => state.user.userInfo,
		// }),
	},
    methods:{
        handleOpen(key, keyPath) {
            // console.log(222,this.menuList);
            // console.log(333,this.userInfo);
            // console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>